import { FillProperty } from 'csstype'
import React from 'react'
import { colors } from '../../../__styling/settings/colors'

export interface PerthNowCockburnLogoProps {
    className?: string
    width?: number
    height?: number
    fill?: FillProperty
    title?: string
}

const PerthNowCockburnLogo: React.FC<PerthNowCockburnLogoProps> = ({
    className,
    title,
    width = 130,
    height = 40,
    fill = colors.black,
}) => {
    return (
        <svg
            width={width}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 132 50"
            preserveAspectRatio="xMinYMid meet"
        >
            <title>{title || 'Perth Now - Cockburn'}</title>
            <path
                d="M10.4634 49.1043C13.2745 49.1043 15.1173 48.5908 16.554 47.9168V43.4554C15.4608 43.9047 14.0553 44.3862 11.8689 44.3862C7.87097 44.3862 6.46544 42.0753 6.46544 37.8064V36.8115C6.46544 32.5426 8.43318 30.8094 11.9314 30.8094C13.8054 30.8094 15.0548 31.0983 16.3666 31.5797V27.0542C15.1485 26.4443 13.3994 26.0913 10.7133 26.0913C4.74757 26.0913 0 29.1404 0 37.2608V38.0311C0 45.5738 3.90425 49.1043 10.4634 49.1043Z"
                fill="black"
            />
            <path
                d="M26.1795 49.0402C31.0207 49.0402 34.6751 46.1515 34.6751 40.2779V39.8606C34.6751 34.3079 31.7079 31.323 26.1795 31.323C21.3382 31.323 17.6838 34.2116 17.6838 40.0532V40.4704C17.6838 45.9268 20.651 49.0402 26.1795 49.0402ZM26.2107 45.1565C24.5241 45.1565 23.3059 44.3541 23.3059 40.4062V39.7643C23.3059 36.2979 24.3367 35.1745 26.1482 35.1745C27.8349 35.1745 29.053 35.9128 29.053 39.9569V40.5667C29.053 44.0652 27.991 45.1565 26.2107 45.1565Z"
                fill="black"
            />
            <path
                d="M44.0775 49.0402C46.5138 49.0402 48.1379 48.4624 49.075 47.8847V44.0331C48.2629 44.3541 47.0135 44.8034 45.3893 44.8034C42.6095 44.8034 41.4226 43.4233 41.4226 40.2137V39.668C41.4226 36.7794 42.9843 35.5597 45.3269 35.5597C47.0135 35.5597 47.8881 35.8486 48.9813 36.2658V32.1896C47.9505 31.6439 46.6075 31.323 44.3274 31.323C39.0488 31.323 35.7068 34.2758 35.7068 40.0853V40.5025C35.7068 46.0552 38.6428 49.0402 44.0775 49.0402Z"
                fill="black"
            />
            <path
                d="M61.0617 48.7192H66.8713L61.2491 39.4754L66.5902 31.6439H61.1242L56.3454 38.8335V25H50.8795V48.7192H56.3454V40.8235L61.0617 48.7192Z"
                fill="black"
            />
            <path
                d="M75.3862 49.0723C81.4456 49.0723 84.6314 46.4404 84.6314 39.8285V39.4434C84.6314 33.2167 81.6017 31.323 78.4783 31.323C76.3857 31.323 74.9177 31.9328 73.762 32.8957H73.6059V25H68.1399V47.8847C70.0139 48.5908 72.5127 49.0723 75.3862 49.0723ZM75.6985 45.1886C74.7615 45.1886 74.0744 44.996 73.6059 44.8034V36.2016C74.1056 35.9448 75.0114 35.6239 75.9484 35.6239C77.9474 35.6239 79.0093 36.4905 79.0093 40.0211V40.5346C79.0093 44.2899 77.5101 45.1886 75.6985 45.1886Z"
                fill="black"
            />
            <path
                d="M91.4349 49.0402C93.5275 49.0402 94.9955 48.3661 96.4635 47.2107H96.6197L96.9945 48.7192H101.805V31.6439H96.3386V44.1294C95.6514 44.4504 94.8081 44.7713 93.8399 44.7713C92.4031 44.7713 91.8409 44.2578 91.8409 42.8777V31.6439H86.375V44.0652C86.375 47.5637 88.249 49.0402 91.4349 49.0402Z"
                fill="black"
            />
            <path
                d="M104.277 48.7192H109.743V37.1645C110.68 36.6831 111.93 36.3942 113.398 36.3942C113.866 36.3942 114.46 36.4905 114.803 36.5547V31.6118C114.616 31.5156 114.272 31.4514 113.835 31.4514C112.086 31.4514 110.587 32.3822 109.712 33.6981H109.556V31.6439H104.277V48.7192Z"
                fill="black"
            />
            <path
                d="M116.383 48.7192H121.849V36.2979C122.536 36.009 123.567 35.7202 124.441 35.7202C125.972 35.7202 126.534 36.2016 126.534 37.5497V48.7192H132V36.009C132 32.6389 130.188 31.323 127.096 31.323C125.004 31.323 123.254 31.9649 121.818 33.1525H121.662V31.6439H116.383V48.7192Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M67.3252 6.7494V16.1452C67.3252 19.8732 70.3918 22.8949 74.1714 22.8949H125.071C128.852 22.8949 131.917 19.8732 131.917 16.1452V6.7494C131.917 3.02272 128.852 0 125.071 0H74.1714C70.3918 0 67.3252 3.02272 67.3252 6.7494Z"
                fill="#D42626"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M103.734 4.37622H107.607L110.705 14.2447H110.764L113.654 4.37622H117.527L120.624 14.2447H120.683L123.662 4.37622H127.266L122.411 18.4746H118.866L115.47 8.84081H115.411L112.403 18.4746H108.709L103.734 4.37622"
                fill="#FFFFFE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M96.4497 15.694C99.071 15.694 100.65 13.6683 100.65 11.4652C100.65 9.26236 99.071 7.23568 96.4497 7.23568C93.8284 7.23568 92.2497 9.26236 92.2497 11.4652C92.2497 13.6683 93.8284 15.694 96.4497 15.694M96.45 4.06372C100.769 4.06372 104.225 7.03008 104.225 11.4652C104.225 15.8997 100.769 18.8673 96.45 18.8673C92.1309 18.8673 88.6758 15.8997 88.6758 11.4652C88.6758 7.03008 92.1309 4.06372 96.45 4.06372"
                fill="#FFFFFE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M74.0923 4.41634H77.4883V6.67778H77.548C78.2029 5.26826 79.5434 4.06372 81.9266 4.06372C85.7692 4.06372 87.1396 6.73671 87.1396 9.61467V18.5147H83.5653V11.3774C83.5653 9.82028 83.4461 7.23569 80.9437 7.23569C78.5907 7.23569 77.6669 8.93953 77.6669 10.8192V18.5147H74.0923V4.41634"
                fill="#FFFFFE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M64.5847 11.4468C64.5657 6.8661 62.7738 4.23139 58.3649 4.23139C56.9007 4.23139 55.6062 4.60982 54.3906 5.6124V0.283447H50.9512V18.5972H54.3906V10.8679C54.3906 9.22943 55.2608 7.53491 58.1298 7.53491C59.9598 7.53491 61.134 8.7636 61.134 10.3587C61.1495 10.7149 61.1524 11.0411 61.1524 11.3027V18.5972H64.5912V11.4629L64.5847 11.4468"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M49.0242 15.1994V15.1911L49.0097 15.1994C47.4429 15.1208 45.9324 13.9723 45.4228 12.7265C45.3076 12.4446 45.2417 11.9788 45.2417 11.4449V7.43773H49.0242V4.54177H45.2417V0.593506H41.8022V10.7454C41.8022 11.2216 41.8495 12.2245 41.94 12.6752C42.6058 16.0028 45.611 18.5133 49.2163 18.5133V15.2082C49.1519 15.2082 49.0886 15.2019 49.0242 15.1994"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.68 4.18715C39.5462 4.171 39.4113 4.15991 39.2716 4.15991L39.2066 4.16403C39.1778 4.16403 39.1497 4.15991 39.1203 4.15991C37.6563 4.15991 36.0534 4.50255 35.145 5.79141V4.50065H31.7065V18.5257H35.145V11.2844L35.1463 11.2353C35.1463 9.28651 36.3095 7.46375 39.1203 7.46375C39.6128 7.46375 40.0826 7.554 40.5189 7.71234V4.29291C40.2443 4.24161 39.9643 4.20931 39.68 4.18715"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.1898 7.46407C24.915 7.46407 26.3838 8.52556 26.9558 10.0136H19.4231C19.9958 8.52556 21.4633 7.46407 23.1898 7.46407M26.5277 13.5837C25.8049 14.6294 24.58 15.3178 23.1894 15.3178C21.3637 15.3178 19.8244 14.1325 19.3306 12.5096H27.047L27.046 12.5121H30.5417C30.5799 12.1362 30.6025 11.7724 30.6025 11.3914C30.6025 7.39725 27.2837 4.15991 23.1885 4.15991C19.0942 4.15991 15.7744 7.39725 15.7744 11.3914C15.7744 15.3856 19.0942 18.6226 23.1885 18.6226C25.6393 18.6226 27.8118 17.4636 29.1616 15.6766L26.5277 13.5837"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.43077 15.3175C5.20691 15.3175 3.40441 13.5597 3.40441 11.3915C3.40441 9.22225 5.20691 7.46346 7.43077 7.46346C9.21225 7.46346 10.7195 8.59334 11.2508 10.1561C11.353 10.5298 11.4064 10.9199 11.4064 11.3161C11.4064 12.1546 11.1684 12.9634 10.7286 13.6401C10.0003 14.6538 8.79607 15.3175 7.43077 15.3175M7.43022 4.15991C3.33595 4.15991 0.0164922 7.39693 0.0164922 11.3914C0.0164922 11.4259 0.0216662 11.4586 0.0216662 11.4921L0 11.5254V22.6339H3.43943V17.4779C4.59259 18.1992 5.9595 18.6223 7.43022 18.6223C11.5251 18.6223 14.8443 15.385 14.8443 11.3914C14.8443 7.39693 11.5251 4.15991 7.43022 4.15991"
                fill="black"
            />
        </svg>
    )
}

export default PerthNowCockburnLogo
